import http from "./interceptor/Axiosinterceptor.js";
export default class DoseService {
  createLogin(data) {
    console.log("add authService");
    return http.post("/auth/signin", data);
  }

  getAll() {
    // console.log("liste de users")
    return http.get("/dose/getListAlldose");
  }
}

