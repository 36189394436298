import http from "./interceptor/Axiosinterceptor.js";
export default class DoseService {
  create(data) {
    console.log("add user UserService");
    return http.post("/user/create", data);
  }

  getAll() {
    // console.log("liste de users")
    return http.get("/dose/getListAlldose");
  }

  remove(id) {
    return http.delete("/user/deleteuser/" + id);
  }

  update(id, data) {
    return http.put("/user/updateUser/" + id, data);
  }

  findByid(id) {
    return http.get("/user/getUserById/" + id);
  }
}
//  getCarsOfOneUser(id){

//  return http.get("/user/CarsOfOneUser/"+id)
// }
